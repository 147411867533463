import { render, staticRenderFns } from "./OrdersPane.vue?vue&type=template&id=312c1788&scoped=true&"
import script from "./OrdersPane.vue?vue&type=script&lang=js&"
export * from "./OrdersPane.vue?vue&type=script&lang=js&"
import style0 from "./OrdersPane.vue?vue&type=style&index=0&id=312c1788&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "312c1788",
  null
  
)

export default component.exports