<template>
    <div class="support"
         :class="{over: ddOver}"
         v-cloak
         @drop.prevent="e => addFile(e)"
         @dragover.prevent
         @dragenter="over(true)"
         @dragleave="over(false)"
         @dragover="over(true)"
         @keyup.esc="toggler = false"
    >
        <div class="fullscreen-page-title">
            {{ tokens.fullScreen.liveChat.toUpperCase() }}
        </div>
        <div class="messages__inner">
            <div class="messages__content">
                <div class="messages" id="support-messages">
                    <div class="message"
                         v-for="message in supportMessages"
                         :key="message.id"
                         :class="'from-'+(message.manager_id ? 'manager' : 'client')"
                    >
                        <div class="text">
                            <div class="text-content">
                                <div v-html="convertText(message.text)"></div>
                                <div v-if="['.jpg', '.png', 'jpeg'].indexOf(message.file.slice(-4)) > -1"
                                     @click="openImg(restEndpoint+'/kyc/document/'+message.file)"
                                     class="pic"
                                >
                                    <img :src="restEndpoint+'/kyc/document/'+message.file">
                                </div>
                                <a v-if="message.file.slice(-4) === '.pdf'"
                                   :href="restEndpoint+'/kyc/document/'+message.file"
                                   class="pdf" target="_blank"
                                >
                                    <i class="material-icons">attach_file</i> PDF attachment
                                </a>
                                <div class="footer">
                                    <div class="time">{{ new Date(message.time_created * 1000).toLocaleString() }}</div>
                                    <i @click="copy(message.text)" v-if="message.text !== ''" class="material-icons copy">content_copy</i>
                                </div>
                            </div>
                            <div class="avatar"><img
                                :src="message.manager_id ? 'image/misc/avatar.png' : user.avatar ? restEndpoint+'/avatar/'+user.avatar : 'image/misc/avatar.png' "/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="send" :class="{fileShow: previewShow}" v-if="chatIsOpen">
                <div v-show="previewShow" class="preview">
                    <img v-show="['image/jpeg', 'image/png'].indexOf(this.typeFile) > -1" :src="preview" alt="">
                    <span v-show="['application/pdf'].indexOf(this.typeFile) > -1">.PDF</span>
                </div>
                <label for="file" class="attach"><i class="material-icons">attach_file</i></label>
                <textarea maxlength="524288" :placeholder="`${tokens.fullScreenSupport.sendMessage}`"
                          id="support-message" class="form-control fs"
                          v-model="message" @keyup.enter="sendMessage"/>
                <div>
                    <input type="file" id="file" ref="file" @change="loadPreview()">
                    <i class="material-icons" @click="sendMessage">send</i>
                </div>
            </div>
            <div v-else class="chat-off">
                {{ tokens.fullScreenSupport.chatOff }}<br>
                <span class="working-time">{{ notWorkingHours.start }} - {{ notWorkingHours.close }} UTC</span>
            </div>
        </div>
        <FsLightbox
            :toggler="toggler"
            :sources="path ? path : []"
        />
    </div>
</template>

<script>
import {mapGetters} from 'vuex'
import {ATTACHMENT_UPLOAD, SUPPORT_READ, SUPPORT_SEND} from '@/store/actions.type'
import {NOTIFICATION, NOTIFICATION_ERROR} from "@/store/mutations.type"
import {REST_ENDPOINT, NOT_WORKING_HOURS} from "@/common/config"
import FsLightbox from "fslightbox-vue"

export default {
    name: "Support",
    components: {FsLightbox},
    mounted() {
        setInterval(() => {
            if (this.modalFullscreen.page === 'support') {
                this.$store.dispatch(SUPPORT_READ)
            }
        }, 3000)
        setTimeout(() => {
            let container = document.getElementById("support-messages")
            container.scrollTop = container.scrollHeight
        }, 300)
        if (this.notWorkingHours) {
            this.chatIsOpen = this.checkWorkingHours()
            setInterval(() => {
                this.chatIsOpen = this.checkWorkingHours()
            }, 1500)
        }
    },
    data() {
        return {
            restEndpoint: REST_ENDPOINT,
            message: '',
            ddOver: false,
            toggler: false,
            path: [],
            preview: '',
            previewShow: false,
            typeFile: '',
            notWorkingHours: NOT_WORKING_HOURS,
            chatIsOpen: true
        }
    },

    methods: {
        convertText(text) {
            let objRE = /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gi;
            return text.replace(objRE, url => '<a style="color: var(--white-to-black); word-break: break-word;" target="_blank" href="' + url + '">' + url + '</a>')
        },
        sendMessage() {
            if (!this.message.trim()) {
                return
            }
            // send message
            if (this.user.enable_chat === 0 || !this.chatIsOpen) {
                this.$store.commit(NOTIFICATION_ERROR, this.tokens.errors.CHAT_DISABLED)
                this.message = ''
                this.$refs.file.value = ''
                this.preview = ''
                this.previewShow = false
                return
            }

            if (this.message) {
                this.$store.dispatch(SUPPORT_SEND, this.message)
                this.message = ''
            }

            // send attached file:
            if (this.$refs.file.files[0]) {
                if (['image/jpeg', 'image/png', 'application/pdf'].indexOf(this.$refs.file.files[0].type) > -1) {
                    let data = new FormData()
                    data.append('file', this.$refs.file.files[0])
                    this.$store.dispatch(ATTACHMENT_UPLOAD, data)
                } else {
                    this.$store.commit(NOTIFICATION_ERROR, 'LOCAL_INVALID_FILE_TYPE')
                }
            }

            this.$refs.file.value = ''
            this.preview = ''
            this.previewShow = false
        },
        copy(text) {
            navigator.clipboard.writeText(text)
                .then(() => {
                    this.$store.commit(NOTIFICATION, 'Message copied to clipboard')
                })
        },
        over(val) {
            this.ddOver = val
        },
        addFile(e) {
            this.ddOver = false

            if (!e.dataTransfer.files) {
                return
            }

            let data = new FormData()

            data.append('file', e.dataTransfer.files[0])

            this.$store.dispatch(ATTACHMENT_UPLOAD, data)
        },
        openImg(path) {
            this.path[0] = path
            this.toggler = !this.toggler
        },
        loadPreview() {
            let file = this.$refs.file.files[0]
            this.typeFile = file.type
            if (['image/jpeg', 'image/png'].indexOf(this.typeFile) > -1) {
                this.preview = URL.createObjectURL(file);
                this.previewShow = true
            } else if (['application/pdf'].indexOf(this.typeFile) > -1) {
                this.typeFile = file.type
                this.preview = ''
                this.previewShow = true
            } else {
                this.preview = ''
                this.preview = false
                this.$store.commit(NOTIFICATION_ERROR, 'LOCAL_INVALID_FILE_TYPE')
            }
        },
        checkWorkingHours() {
            let date = new Date()
            let startHour = Math.floor(this.notWorkingHours.start.slice(0, 2))
            let closeHour = Math.floor(this.notWorkingHours.close.slice(0, 2))

            let startMinute = Math.floor(this.notWorkingHours.start.substring(this.notWorkingHours.start.length, this.notWorkingHours.start.length - 2))
            let closeMinute = Math.floor(this.notWorkingHours.close.substring(this.notWorkingHours.close.length, this.notWorkingHours.close.length - 2))

            let currentHour = date.getUTCHours()
            let currentMinute = date.getUTCMinutes()

            return currentHour === startHour && currentMinute >= startMinute || (currentHour > startHour && !(currentHour >= closeHour && currentMinute >= closeMinute))
        }
    },
    computed: {
        ...mapGetters(['supportMessages', 'modalFullscreen', 'user', 'tokens'])
    },
    watch: {
        supportMessages() {
            setTimeout(() => {
                let container = document.getElementById("support-messages")
                container.scrollTop = container.scrollHeight
            }, 300)
        },
        modalFullscreen: {
            deep: true,
            handler(val) {
                if (val.page === 'support') {
                    setTimeout(() => {
                        let container = document.getElementById("support-messages")
                        container.scrollTop = container.scrollHeight
                    }, 300)
                }
            }
        }
    }
}
</script>

<style scoped>
.over {
    background: rgba(255, 255, 255, .2);
}

.messages__inner {
    position: relative;
    height: 90%;
}

.messages__content {
    height: 90%;
}

.avatar {
    height: 3.2vw;
    width: 3.2vw;
}

.footer {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
    gap: 30px;
    align-items: center;
}

.footer i {
    cursor: pointer;
    font-size: 21px;
}

.pic {
    height: 125px;
    cursor: pointer;
    position: relative;
    z-index: 3;
}

.pic img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.avatar img {
    height: 100%;
    width: 100%;
    object-fit: cover;
    border-radius: 100%;
}

.messages {
    display: flex;
    flex-direction: column;
    overflow-y: scroll;
    padding: 0 20px 15px;
    margin-bottom: 15px;
    height: 100%;
}

.chat-off {
    padding: 30px;
    text-align: center;
    background: var(--background-dark-mid);
    border-radius: 5px;
}

.chat-off .working-time {
    line-height: 170%;
    color: var(--white-to-black);
}

.message {
    display: flex;
    flex-direction: column;
    position: relative;
    margin-bottom: 10px;
}

.message:last-child {
    margin-bottom: 0;
}

.message.from-manager {
    align-items: flex-start;
}

.message.from-client {
    align-items: flex-end;
}

.message.from-manager .avatar {
    grid-area: 1/1;
}

.message.from-manager .text-content {
    background: var(--background-dark-mid);
}

.text {
    display: grid;
    grid-template-columns: repeat(2, auto);
    gap: 10px;
}

.preview {
    border: 1px solid var(--fs-border);
    border-radius: 10px;
    padding: 5px;
}

.preview span {
    font-weight: bold;
    font-size: 21px;
}

.preview img {
    width: 50px;
    height: 50px;
    object-fit: contain;
}

.text-content {
    padding: 12px 15px;
    background: var(--background-dark-mid);
    border: 1px solid rgba(255, 255, 255, 0.3);
    color: var(--white-to-black);
    font-weight: 400;
    font-size: 1.1em;
    max-width: 60%;
    word-break: break-all;
    border-radius: 10px;
    position: relative;
}

.text-content {
    font-weight: 300;
    font-size: 14px;
    line-height: 23px;
}

.time {
    margin: 10px 0;
    font-weight: 300;
    font-size: 14px;
    line-height: 1;
    color: var(--white-to-black);
    opacity: 0.6;
}

#file {
    display: none;
}

label.attach {
    cursor: pointer;
    font-size: 14px;
    font-weight: 400;
    margin: 0;
}

label.attach i {
    transform: rotate(45deg);
}

i {
    font-size: 32px;
    cursor: pointer;
}

i:hover {
    color: var(--white-to-black);
}

.send.fileShow {
    grid-template-columns: auto auto 1fr auto;
}

.send {
    display: grid;
    grid-template-columns: auto 1fr auto;
    gap: 15px;
    padding: 20px 30px;
    border-top: 1px solid rgba(255, 255, 255, 0.5);
    align-items: center;
}

textarea {
    width: 100%;
    font-family: inherit;
}

.send button {
    font-size: 14px;
    font-weight: 400;
}

a.pic img {
    width: 200px;
}

a.pdf {
    color: var(--white-to-black);
}

textarea[name=support-message] {
    resize: none;
}

#support-message {
    resize: none;
    background: var(--background);
}

.footer .copy {
    position: relative;
    z-index: 10;
}

@media (min-width: 1000px) {
    .messages {
        padding: 0 1vw .7vw;
        height: 90%;
        margin-bottom: .7vw;
    }

    .preview {
        border: .1vw solid var(--fs-border);
        border-radius: .5vw;
        padding: .4vw;
    }

    .preview span {
        font-size: 1vw;
    }

    .preview img {
        width: 3vw;
        height: 3vw;
    }

    .message {
        margin-bottom: 1.7vw;
    }

    .footer i {
        font-size: 1.1vw;
    }

    .text {
        gap: .5vw;
    }

    .text-content {
        padding: 1vw .7vw;
        /*border-top: .1vw solid rgba(255, 255, 255, 0.3);*/
        /*border-right: .1vw solid rgba(255, 255, 255, 0.3);*/
        /*border-left: .1vw solid rgba(255, 255, 255, 0.3);*/
        border: .1vw solid rgba(255, 255, 255, 0.3);
        /*border-top-right-radius: .5vw;*/
        /*border-top-left-radius: .5vw;*/
        border-radius: .5vw;
        max-width: 20vw;
    }

    .text-content span {
        position: relative;
        cursor: pointer;
        z-index: 3;
    }

    .text-content {
        font-size: 1vw;
        line-height: 1.2vw;
        padding: .6vw .7vw;
    }

    .time {
        margin: 1vw 0 0;
        font-size: .7vw;
    }


    label.attach {
        font-size: .7vw;
    }

    i {
        font-size: 1.6vw;
    }

    .send {
        gap: .7vw;
        padding: 1vw 1.5vw;
        border-top: .1vw solid rgba(255, 255, 255, 0.5);
    }

    .send button {
        font-size: .7vw;
    }

    a.pic img {
        width: 10vw;
    }
}

@media (max-width: 1000px) {

    .send {
        padding: 20px 15px;
        position: fixed;
        bottom: 0;
        left: 0;
        width: 100%;
        background: var(--background-dark);
        z-index: 10;
    }

    .avatar {
        height: 30px;
        width: 30px;
    }

    a.pic img {
        width: 100%;
    }

    .text-content {
        max-width: 100%;
    }
}

</style>
