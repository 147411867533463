import { render, staticRenderFns } from "./OrdersTable.vue?vue&type=template&id=4db60cd4&scoped=true&"
import script from "./OrdersTable.vue?vue&type=script&lang=js&"
export * from "./OrdersTable.vue?vue&type=script&lang=js&"
import style0 from "./OrdersTable.vue?vue&type=style&index=0&id=4db60cd4&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4db60cd4",
  null
  
)

export default component.exports