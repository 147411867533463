import { render, staticRenderFns } from "./ClosePositionModal.vue?vue&type=template&id=5f225750&scoped=true&"
import script from "./ClosePositionModal.vue?vue&type=script&lang=js&"
export * from "./ClosePositionModal.vue?vue&type=script&lang=js&"
import style0 from "./ClosePositionModal.vue?vue&type=style&index=0&id=5f225750&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5f225750",
  null
  
)

export default component.exports