<template>
    <div class="toasts-container">
        <div class="toast" v-for="notification in displayNotifications"
             :key="notification.id"
             :class="{support: notification.type === 'support' || notification.type === 'margin_call'}"
         >
            <div class="icon">
                <span v-if="notification.type === 'order' || notification.type === 'order_open'"
                      class="symbol"
                      :class="'symbol-'+notification.data.symbol.toUpperCase()"/>
<!--                <img :src="notification.type === 'order' || notification.type === 'order_open' ? staticBase+notification.data.symbol.toUpperCase()+'.png' : ''"-->
<!--                     :class="{-->
<!--                        hide: notification.type !== 'order' && notification.type !== 'order_open'-->
<!--                     }"/>-->
                <i class="material-icons" :class="notification.classes || 'brand'">{{ notification.icon || notification.type === 'margin_call' ? 'warning' : '' }}</i>
            </div>
            <div class="content"  @click="pushTo(notification.type)">
                <div class="title">{{ notification.title }}</div>
                <div class="text" :class="notification.textClass">
                    <span class="profit" :class="{hide: notification.type !== 'order' && notification.type !== 'transaction'}">{{ notification.profitText }}</span>
                    <span>{{ notification.text }}</span>
                </div>
            </div>
            <i class="material-icons close" v-on:click="hideNotification(notification.id)">close</i>
        </div>
    </div>
</template>

<script>
    import {mapGetters} from 'vuex'
    import {STATIC_BASE,SELF_WITHDRAWAL_CANCEL_TEXT} from "@/common/config";
    import {currencySign} from "@/common/helpers";
    import {MODAL, MODAL_FULLSCREEN, NOTIFICATION_REMOVE} from "@/store/mutations.type";

    export default {
        name: "Notifications",
        data() {
            return {
                staticBase: STATIC_BASE
            }
        },
        methods: {
            currencySign,
            showCabinetPage(page) {
                this.$store.commit(MODAL, {
                    fullscreen: true
                })

                this.$store.commit(MODAL, {
                    hamburgerMenu: false
                })

                this.$store.commit(MODAL_FULLSCREEN, {
                    page
                })
            },
            hideNotification(id) {
                this.$store.commit(NOTIFICATION_REMOVE, id)
            },
            pushTo(type) {
                switch (type) {
                    case 'support':
                        this.showCabinetPage('support')
                        break
                    case 'margin_call':
                        this.showCabinetPage('deposit')
                        break
                    default:
                        break
                }
            }
        },
        computed: {
            ...mapGetters(['notifications', 'symbols', 'activeAccount', 'tokens']),
            displayNotifications() {
                let nots = JSON.parse(JSON.stringify(this.notifications))

                for(let n in nots) {
                    switch (nots[n].type) {
                        case 'order':
                            nots[n].title = (this.symbols[nots[n].data.symbol] ? this.symbols[nots[n].data.symbol].alias : '')
                            nots[n].textClass = nots[n].data.profit > 0 ? 'green' : (nots[n].data.profit < 0 ? 'red' : '')
                            nots[n].profitText = (nots[n].data.profit > 0 ? '+' : (0 > nots[n].data.profit ? '-' : ''))+currencySign(this.activeAccount.currency)+Math.abs(nots[n].data.profit).toFixed(this.symbols[nots[n].data.symbol] ? this.symbols[nots[n].data.symbol].precision : 2)
                            nots[n].text = (nots[n].data.profit > 0 ? '+' : (0 > nots[n].data.profit ? '-' : ''))+(100 * Math.abs(nots[n].data.price_close - nots[n].data.price_open) / nots[n].data.price_open).toFixed(2)+'%'
                            nots[n].icon = 'thumb_up'
                            nots[n].classes = { hide: true }
                            break
                        case 'order_open':
                            nots[n].title = this.tokens.orderTypes[nots[n].data.type]+' '+(this.symbols[nots[n].data.symbol] ? this.symbols[nots[n].data.symbol].alias : '')
                            nots[n].textClass = nots[n].data.type % 2 ? 'red' : 'green'
                            nots[n].profitText = ''
                            nots[n].text = Number(nots[n].data.volume.toFixed(4))+' '+nots[n].data.symbol+' @ '+nots[n].data.price_open.toFixed(this.symbols[nots[n].data.symbol] ? this.symbols[nots[n].data.symbol].precision : 2)
                            nots[n].icon = 'thumb_up'
                            nots[n].classes = { hide: true }
                            break
                        case 'success':
                            nots[n].title = this.tokens.notifications.success
                            nots[n].textClass = ''
                            nots[n].profitText = ''
                            nots[n].text = this.tokens.errors[nots[n].text] || nots[n].text
                            nots[n].icon = 'thumb_up'
                            nots[n].classes = { green: true }
                            break
                        case 'error':
                            nots[n].title = this.tokens.notifications.error
                            nots[n].textClass = ''
                            nots[n].profitText = ''
                            nots[n].text = this.tokens.errors[nots[n].text] || nots[n].text
                            nots[n].icon = 'report_problem'
                            nots[n].classes = { brand: true }
                            break
                        case 'support':
                            nots[n].title = ''
                            nots[n].textClass = ''
                            nots[n].profitText = ''
                            nots[n].icon = 'mail'
                            nots[n].classes = { green: true }
                            break
                        case 'transaction':
                            nots[n].title = this.tokens.notifications[(nots[n].data.type === 1 ? 'deposit' : 'withdrawal')]+' #'+nots[n].data.id
                            nots[n].text = this.tokens.notifications[(nots[n].data.type === 1 ? 'deposit' : 'withdrawal')+(nots[n].data.status === 1 ? 'Approved' : SELF_WITHDRAWAL_CANCEL_TEXT && nots[n].data.info === 'Self cancelled' ? 'Cancelled' : 'Declined')]
                            nots[n].textClass = ''
                            nots[n].profitText = nots[n].data.status === 1 ? (nots[n].data.amount > 0 ? '+' : '-')+currencySign(nots[n].data.currency)+Math.abs(nots[n].data.amount).toLocaleString(undefined, {minimumFractionDigits: this.activeAccount.precision, maximumFractionDigits: this.activeAccount.precision}) : ''
                            nots[n].icon = nots[n].data.status === 1 ? 'check_circle' : 'cancel'
                            nots[n].classes = { green: nots[n].data.status === 1, red: nots[n].data.status === 2 }
                            break
                    }
                }

                return nots
            }
        }
    }
</script>

<style scoped>
    .toasts-container {
        position: fixed;
        bottom: 40px;
        left: 120px;
        z-index: 10000
    }

    .toast {
        width: 300px;
        display: flex;
        align-items: center;
        background: var(--background);
        border: 1px solid var(--border);
        padding: 20px;
        border-radius: 3px;
        position: relative;
        margin-top: 5px;
    }

    .icon {
        flex: 0 0 80px;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
    }

    .icon img {
        width: 32px;
        height: 32px;
    }

    .icon span.symbol {
        transform: scale(0.75);
        left: -20px;
        top: -9px;
    }

    .icon i {
        font-size: 3em;
    }

    .content {
        display: flex;
        flex-direction: column;
    }

    .title {
        color: var(--white-to-black);
        font-size: 1.3em;
        font-weight: 400;
        margin-bottom: 8px;
    }

    .text {
        color: var(--white-to-black);
        font-size: 1.1em;
        font-weight: 400;
        display: flex;
        flex-direction: column;
    }

    .profit {
        font-size: 1.2em;
        margin: 0 5px 5px 0;
    }

    .close {
        position: absolute;
        top: 5px;
        right: 5px;
        cursor: pointer;
        font-size: 2em;
        color: var(--white-to-black)
    }

    .toast.support{
        cursor: pointer;
    }

    @media (max-width: 767px) {
        .toasts-container {
            left: 50%;
            margin-left: -150px;
        }
    }
</style>
