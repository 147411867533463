<template>
    <div class="dashboard" :class="{mobile: isMobile}">
        <div class="total-balance">
            <div class="left">
                <img :src="staticBase+`misc/goblet.png`">
            </div>
            <div class="right">
                <h4 class="title">{{ tokens.fullScreenDashboard.totalBalance }}</h4>
                <p class="value"
                   :class="totalBalance < 0 ? 'red-gradient' : (totalBalance >= 0 ? 'green-gradient' : '')">
                    {{
                        activeCurrency && totalBalance >= 0 ? currencySign(activeCurrency) + totalBalance.toLocaleString(undefined, {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2
                        }) : `${currencySign('USD')} 0`
                    }}</p>
                <span v-if="!dashboardData.stats.currency">* {{ tokens.fullScreenDashboard.usingCurrentRate }}</span>
            </div>
        </div>
        <div class="total-pnl">
            <div class="left">
                <img :src="staticBase+`misc/gem.png`">
            </div>
            <div class="right">
                <h4 class="title">{{ tokens.fullScreenDashboard.totalPnl }}</h4>
                <p class="value"
                   :class="pnl < 0 ? 'red-gradient' : pnl >= 0 ? 'green-gradient' : 'green-gradient'">
                    {{
                        activeCurrency ? currencySign(activeCurrency) + pnl.toLocaleString(undefined, {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2
                        }) : `${currencySign('USD')} 0`
                    }}
                </p>
                <span v-if="!dashboardData.stats.currency">* {{ tokens.fullScreenDashboard.usingCurrentRate }}</span>
            </div>
        </div>
        <div class="profitable-orders">
            <div class="left">
                <img :src="staticBase+`misc/target.png`">
            </div>
            <div class="right">
                <h4 class="title">{{ tokens.fullScreenDashboard.profitableOrders }}</h4>
                <p class="value green-gradient">
                    {{ dashboardData.stats.cnt_profit ? dashboardData.stats.cnt_profit : "0" }} / {{ cntTotal }}</p>
            </div>
        </div>
        <div class="roi">
            <div class="left">
                <img :src="staticBase+`misc/totalDeposit.png`">
            </div>
            <div class="right">
                <h4 class="title">{{ tokens.fullScreenDashboard[showTaxes ? 'totalTaxes' : 'totalDeposits'] }}</h4>
                <p class="value roi-value"
                   :class="dashboardData.stats.deposits < 0 ? 'red-gradient' : 'green-gradient'">
                    {{
                        activeCurrency && showTaxes ? (totalTaxes > 0 ? currencySign(activeCurrency) + totalTaxes.toLocaleString(undefined, {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2
                        }) : `${currencySign(activeCurrency ? activeCurrency : 'USD')} 0`) : (totalDeposits > 0 ? currencySign(activeCurrency) + totalDeposits.toLocaleString(undefined, {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2
                        }) : `${currencySign(activeCurrency ? activeCurrency : 'USD')} 0`)
                    }}
                </p>
                <span v-if="!dashboardData.stats.currency">* {{ tokens.fullScreenDashboard.usingCurrentRate }}</span>
            </div>
        </div>

        <div class="chart-line" v-if="!showTaxes">
            <div class="chart-line-head" v-if="!hideTradingResult">
                <h4>{{ tokens.fullScreenDashboard.tradingResults }}</h4>
                <div class="time" v-if="!hideTradingResult">
                    <button class="time-item fs-small" :class="{active: timeframe === 'Week'}"
                            @click="changeTimeframe('Week',7)">{{ tokens.fullScreenDashboard.week }}
                    </button>
                    <button class="time-item fs-small" :class="{active: timeframe === 'Month'}"
                            @click="changeTimeframe('Month',30)">{{ tokens.fullScreenDashboard.month }}
                    </button>
                </div>
            </div>
            <div style="display: grid; height: 100%" v-if="!hideTradingResult"> <!-- hack for highchart height -->
                <highcharts :options="chartOptionsLine" ref="lineChart"/>
            </div>
        </div>
        <div class="chart-line" v-else>
            <table class="cabinet fs">
                <thead>
                <tr class="fs">
                    <th class="nomobile fs" v-if="!hideDashboardTime">{{ tokens.fullScreenDeposit.time.toUpperCase() }}</th>
                    <th class="fs">{{ tokens.fullScreenDeposit.amount.toUpperCase() }}</th>
                    <th class="fs">{{ tokens.fullScreenDeposit.currency.toUpperCase() }}</th>
                    <th class="fs">{{ tokens.fullScreenDeposit.info.toUpperCase() }}</th>
                </tr>
                </thead>
                <tbody>
                <tr
                    v-for="tax in taxes"
                    :key="tax.id"
                    class="fs"
                >
                    <td class="nomobile fs" v-if="!hideDashboardTime">{{ new Date(tax.time_created * 1000).getFullYear() }}</td>
                    <td class="fs">{{ currencySign(tax.currency)+tax.amount.toFixed(2) }}</td>
                    <td class="fs">{{ tax.currency }}</td>
                    <td class="fs">{{ tax.comment }}</td>
                </tr>
                </tbody>
            </table>
        </div>
        <div class="chart-pie">
            <h4>{{ tokens.fullScreenDashboard.successRate }}</h4>
            <div style="display: grid; height: 100%"> <!-- hack for highchart height -->
                <highcharts :options="chartOptionsPie" ref="pieChart"/>
            </div>
        </div>
        <div class="account" v-for="account in accounts ? accounts : ''"
             :key="account.id"
             :class="{
                        active: account && activeAccount.id === account.id,
                        real: account && !account.demo,
                        demo: account && account.demo
                    }">
            <div class="head">
                <h3>{{ tokens.fullScreenDashboard.account }}</h3>
                <div class="balances">
                    <img :src="plate[account.id]">
                    <img class="sign balance" :src="`${staticBase}currencies/${account.currency}.png`" alt="">
                </div>
            </div>
            <div class="body">
                <div class="info">
                    <h4>{{ tokens.fullScreenDashboard.balance }}</h4>
                    <p class="value"
                       :class="account.balance < 0 ? 'red-gradient' : (account.balance >= 0 ? 'green-gradient' : '')">
                        {{
                            currencySign(account.currency) + ' ' + (account.balance > 0 ? account.balance : 0).toLocaleString(undefined, {
                                minimumFractionDigits: account.precision,
                                maximumFractionDigits: account.precision
                            })
                        }}</p>
                </div>
                <div class="info" v-show="parseInt(showLeverage) === 1">
                    <h4>{{ tokens.fullScreenDashboard.leverage }}</h4>
                    <p class="value"
                       :class="account.leverage < 0 ? 'red-gradient' : (account.leverage >= 0 ? 'green-gradient' : '')">
                        {{ '1:' + account.leverage }}</p>
                </div>
                <div class="info">
                    <h4>{{ tokens.fullScreenDashboard.credit }}</h4>
                    <p class="value"
                       :class="account.credit < 0 ? 'red-gradient' : (account.credit >= 0 ? 'green-gradient' : '')">
                        {{
                            currencySign(account.currency) + ' ' + (account.credit).toLocaleString(undefined, {
                                minimumFractionDigits: account.precision,
                                maximumFractionDigits: account.precision
                            })
                        }}</p>
                </div>
            </div>
            <div class="footer" v-if="!moduleFtd">
                <button class="fs" @click="makeActive(account.id)">{{ tokens.fullScreenDashboard.tradeNow }}</button>
            </div>
        </div>
    </div>
</template>

<script>
import {mapGetters} from 'vuex'
import {currencySign, isMobile, localStorage} from '@/common/helpers'
import {
    PLATES_FOLDER,
    STATIC_BASE,
    BRAND,
    SHOW_LEVERAGE,
    MODULE_FTD,
    HIDE_TRADING_RESULT, SHOW_TAXES, HIDE_DASHBOARD_TIME
} from "@/common/config";
import {ACCOUNT_ACTIVE, ACTIVE_CURRENCY, MODAL} from "@/store/mutations.type";
import {DASHBOARD_BALANCE} from "@/store/actions.type";

export default {
    name: 'Dashboard',
    mounted() {
        let dashboardBalance = this.dashboardData.balance_chart
        let data = []
        for (let i = 0; i < dashboardBalance.length; i++) {
            data[i] = dashboardBalance[i][1]
        }
        this.chartOptionsLine.series[0].data = data
        this.foundMinMax()
        this.dashboardChartInit()

        this.chartOptionsPie.legend.itemStyle.color = this.brand === 'coinxe' ? '#000000' : '#235fb7'
    },
    data() {
        return {
            isMobile: isMobile(),
            brand: BRAND,
            hideDashboardTime: HIDE_DASHBOARD_TIME,
            staticBase: STATIC_BASE,
            platesFolder: PLATES_FOLDER,
            showLeverage: SHOW_LEVERAGE,
            moduleFtd: MODULE_FTD,
            showTaxes: SHOW_TAXES,
            hideTradingResult: HIDE_TRADING_RESULT,
            time: 7,
            timeframeData: [],
            timeframe: 'Week',
            balanceChart: [],
            allTime: [],
            chartOptionsLine: {
                title: false,
                credits: false,
                chart: {
                    backgroundColor: 'transparent',
                    type: 'areaspline',
                },
                plotOptions: {
                    areaspline: {
                        fillOpacity: 0.5
                    },
                    series: {
                        fillColor: {
                            linearGradient: [0, 0, 50, 300],
                            stops: [
                                [0, '#1d98f9'],
                                [1, 'rgba(24,31,45,0.5)']
                            ]
                        },

                    }
                },
                legend: true,
                xAxis: {
                    labels: {
                        style: {
                            fontSize: '0px'
                        },
                    },
                    gridLineWidth: 0,
                    tickLength: 0,
                    min: 0,
                    categories: []
                },
                yAxis: {
                    title: {
                        text: null,
                    },
                    labels: {
                        style: {
                            fontSize: '0px'
                        },
                    },
                    min: 0,
                    gridLineWidth: 0,
                    tickLength: 0,
                    display: true,
                },
                lineWidth: 1,
                threshold: null,
                // innerSize: '60%',
                enableMouseTracking: true,

                tooltip: {
                    headerFormat: '{point.x}<br>',
                    pointFormat: '<strong>$ {point.y}',
                    shared: true,
                    style: {
                        fontSize: window.innerWidth > 1400 ? ".8vw" : "12px",
                    }
                },
                series: [{
                    name: '$',
                    data: [],
                    marker: {
                        enable: false,
                        fillColor: '#91BDFF',
                        states: {
                            hover: {
                                enabled: true
                            }
                        }
                    },
                }]
            },
            chartOptionsPie: {
                credits: false,
                chart: {
                    plotBackgroundColor: null,
                    plotBorderWidth: null,
                    plotShadow: false,
                    type: 'pie',
                    backgroundColor: 'transparent',
                },
                title: {
                    text: 'adsad',
                    align: 'center',
                    verticalAlign: 'middle',
                    y: 0,
                    style: {"color": "#92B8F1", "fontSize": window.innerWidth > 1400 ? "1vw" : "12px"}
                },
                tooltip: {
                    pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>',
                    style: {
                        fontSize: window.innerWidth > 1400 ? ".8vw" : "12px",
                    }
                },
                accessibility: {
                    point: {
                        valueSuffix: '%'
                    }
                },
                plotOptions: {
                    pie: {
                        allowPointSelect: true,
                        cursor: 'pointer',
                        borderWidth: 0,
                        dataLabels: {
                            enabled: false,
                            format: '<b>{point.name}</b>: {point.percentage:.1f} %',
                        }
                    }
                },
                legend: {
                    enabled: true,
                    itemStyle: {"fontWeight": "normal", "fontSize": window.innerWidth > 1400 ? ".7vw" : "12px"}
                },
                series: [{
                    name: 'Orders',
                    colorByPoint: true,
                    innerSize: '60%',
                    showInLegend: true,

                    data: [
                        {
                            name: '',
                            y: 1,
                            color: {
                                linearGradient: {x1: 0, x2: 0, y1: 0, y2: 1},
                                stops: [
                                    [0, '#DDE120'], // start
                                    [1, '#338C27'] // end
                                ]
                            },
                        },
                        {
                            name: '',
                            y: 1,
                            color: {
                                linearGradient: {x1: 0, x2: 0, y1: 0, y2: 1},
                                stops: [
                                    [0, '#B41717'], // start
                                    [1, '#2A6DBC'] // end
                                ]
                            },

                        }
                    ]
                }]
            }
        }
    },
    methods: {
        currencySign,
        close() {
            this.$store.commit(MODAL, {
                fullscreen: false
            })
        },
        changeTimeframe(timeframe, time) {
            if (this.time !== time) {
                this.time = time
                this.timeframe = timeframe
                this.$store.dispatch(DASHBOARD_BALANCE, this.time * 24)

                this.chartOptionsLine.series[0].data = this.getTimeframeData
                this.foundMinMax()
            }
        },
        foundMinMax() {
            this.chartOptionsLine.yAxis.min = Math.min.apply(null, this.chartOptionsLine.series[0].data)
            this.chartOptionsLine.yAxis.max = Math.max.apply(null, this.chartOptionsLine.series[0].data)
        },
        makeActive(id) {
            this.$store.commit(MODAL, {
                fullscreen: false
            })
            localStorage.setItem('activeAccount', id)
            this.$store.commit(ACCOUNT_ACTIVE, id)
        },
        dashboardChartInit() {
            this.chartOptionsPie.series[0].data[0].y = this.dashboardData.stats.cnt_profit ? parseInt(this.dashboardData.stats.cnt_profit) : 0
            this.chartOptionsPie.series[0].data[1].y = this.cntTotal ? ((this.cntTotal - this.dashboardData.stats.cnt_profit) === 0) ? 0 : ((this.cntTotal) - (this.dashboardData.stats.cnt_profit)) : 1
            let result = (this.chartOptionsPie.series[0].data[0].y / this.cntTotal === 0) ? 0 : (this.chartOptionsPie.series[0].data[0].y / this.cntTotal) * 100

            this.chartOptionsPie.title.text = isNaN(result) ? "0%" : result.toFixed(1) + "%"
            this.chartOptionsLine.xAxis.categories = this.getTimeData

            this.chartOptionsPie.series[0].data[0].name = this.tokens.fullScreenDashboard.closedWithProfit
            this.chartOptionsPie.series[0].data[1].name = this.tokens.fullScreenDashboard.closedWithLoss

            this.chartOptionsPie.legend.itemStyle.color = '#235fb7'
        },
        isOneAccountCurrency(accounts, accountsValue) {
            let isOneAccountCurrency = true

            for (let i = 0; i < accounts.length; i++) {
                if (!(accounts[i] === this.activeCurrency && accounts.length === accountsValue.length)) {
                    return false
                }
            }

            return isOneAccountCurrency
        },
        convToActiveDashCurrency(value) {
            if (this.activeCurrency !== 'USD' && value) {
                return value / this.conversions[this.activeCurrency]
            }
            return value
        },
        convertToUSD(value, accountsCurrency, accountValues) {
            value = 0
            let acc

            for (let i = 0; i < accountValues.length; i++) {
                acc = 1

                if (accountsCurrency[i] !== 'USD') {
                    acc = this.conversions[accountsCurrency[i]]?.toFixed(4)
                }

                value += acc * accountValues[i]
            }
            return value
        }
    },
    computed: {
        ...mapGetters(['conversions','user', 'tokens', 'modals', 'activeAccount', 'accounts', 'dashboardData', 'symbols', 'orders', 'activeCurrency','taxes']),
        plate() {
            let plate = []
            for (const account in this.accounts) {
                let metal = ['tester','beginner', 'student', 'basic','basicx', 'bronze', 'bronze_plus', 'silver', 'silver_plus', 'gold', 'platinum', 'platinum_plus', 'vip', 'top', 'diamond','aitop','pro','pro_1','pro_2','diamond_2','vip_2','platinum_2','gold_2','corporate','arbitrage','shielded', 'islamic'].includes(this.accounts[account].group) ? this.accounts[account].group : 'main'
                plate[this.accounts[account].id] = this.staticBase + this.platesFolder + '/' + metal + '_plate.png'
            }
            return plate
        },
        getTimeframeData() {

            let dashboardBalance = this.dashboardData.balance_chart
            let data = []
            for (let i = 0; i < dashboardBalance.length; i++) {
                data[i] = dashboardBalance[i][1]
            }
            return data
        },
        getTimeData() {
            let dashboardBalance = this.dashboardData.balance_chart
            let timeData = []
            for (let i = 0; i < dashboardBalance.length; i++) {
                timeData[i] = new Date(dashboardBalance[i][0] - 1000 * 60 * 60).toLocaleString()
            }
            return timeData
        },
        totalBalance() {
            if (!Object.keys(this.dashboardData.stats).length && !Object.keys(this.dashboardData.stats.accounts).length) {
                return 0
            }

            let accBalance = Object.values(this.accounts).map(acc => acc.balance)
            let accountsCur = Object.values(this.accounts).map(acc => acc.currency)
            let balanceTotal = accBalance.reduce((total, balance) => total + balance, 0)

            if (this.isOneAccountCurrency(accountsCur, accBalance) && this.activeCurrency !== 'USD') {
                return balanceTotal
            } else {
                balanceTotal = this.convertToUSD(balanceTotal, accountsCur, accBalance)
                return this.convToActiveDashCurrency(balanceTotal)
            }
        },
        totalDeposits() {


            if (Object.keys(this.accounts).length <= 1 && this.activeCurrency === this.dashboardData.stats.currency && this.dashboardData.stats.currency !== 'USD') {
                return this.dashboardData.stats.currency_dep
            }

            if (this.activeCurrency !== 'USD') {
                return this.convToActiveDashCurrency(this.dashboardData.stats.deposits)
            }
            return this.dashboardData.stats.deposits
        },
        totalTaxes() {
            if (this.activeCurrency !== 'USD') {
                return this.convToActiveDashCurrency(this.dashboardData.stats.taxes)
            }
            return this.dashboardData.stats.taxes
        },
        pnl() {
            let pnlTotal = 0
            let pnl = []
            let accountsCur = []
            let accountId = Object.values(this.accounts).map(acc => acc.id)
            let dashAccId = []

            if (this.dashboardData.stats && this.dashboardData.stats.accounts) {
                dashAccId = Object.values(this.dashboardData.stats.accounts).map(acc => acc.account_id)
            }

            for (let i = 0; i < accountId.length; i++) {
                const index = dashAccId.indexOf(accountId[i])
                if (index !== -1) {
                    pnl.push(this.dashboardData.stats.accounts[index].profit)
                    accountsCur.push(this.accounts[accountId[i]].currency)
                }
            }

            if (this.isOneAccountCurrency(accountsCur, pnl) && this.activeCurrency !== 'USD') {
                for (let i = 0; i < pnl.length; i++) {
                    pnlTotal += pnl[i]
                }
                return pnlTotal
            } else {
                pnlTotal = this.convertToUSD(pnlTotal, accountsCur, pnl)
                return this.convToActiveDashCurrency(pnlTotal)
            }
        },
        amount() {
            let amountTotal = 0
            let amount = []
            let accountsCur = []
            let accountId = Object.values(this.accounts).map(acc => acc.id)
            let dashAccId = []

            if (this.dashboardData.stats && this.dashboardData.stats.accounts) {
                dashAccId = Object.values(this.dashboardData.stats.accounts).map(acc => acc.account_id)
            }

            for (let i = 0; i < accountId.length; i++) {
                const index = dashAccId.indexOf(accountId[i])
                if (index !== -1) {
                    amount.push(this.dashboardData.stats.accounts[index].amount)
                    accountsCur.push(this.accounts[accountId[i]].currency)
                }
            }

            if (this.isOneAccountCurrency(accountsCur, amount)) {
                return amountTotal
            } else if (!this.symbols[this.activeCurrency + 'USD'] && !this.symbols['USD' + this.activeCurrency] && this.activeCurrency !== 'USD') {
                return 0
            } else {
                amountTotal = this.convertToUSD(amountTotal, accountsCur, amount)
                return this.convToActiveDashCurrency(amountTotal)
            }
        },
        cntTotal() {
            let cntTotal = 0

            for (const cntStat in this.dashboardData.stats.accounts) {
                cntTotal += this.dashboardData.stats.accounts[cntStat].cnt_total
            }

            return cntTotal
        },
    },
    watch: {
        modals: {
            deep: true,
            handler() {
                if (this.modals.fullscreen && this.$refs.lineChart && this.$refs.pieChart) {
                    this.$refs.lineChart.chart.reflow();
                    this.$refs.pieChart.chart.reflow();
                }
            }
        },
        dashboardData: {
            deep: true,
            handler() {
                let dashboardBalance = this.dashboardData.balance_chart
                let data = []
                for (let i = 0; i < dashboardBalance.length; i++) {
                    data[i] = dashboardBalance[i][1]
                }

                this.chartOptionsLine.series[0].data = data
                this.foundMinMax()
                this.dashboardChartInit()
            }
        },
        activeAccount: {
            handler() {
                if (this.activeAccount !== undefined) {
                    this.$store.commit(ACTIVE_CURRENCY, localStorage.activeCurrency)

                }
            }
        }
    }
}
</script>

<style scoped>


.dashboard {
    display: grid;
    grid-template-columns: repeat(8, 1fr);
    grid-template-rows: auto auto 300px auto;
    gap: 30px;
    align-items: stretch;
}

.dashboard > * {
    border-radius: 10px;
    padding: 20px;
    background: var(--background-dark-mid);
    overflow: hidden;
}

.total-balance,
.total-pnl,
.profitable-orders,
.roi {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    padding: 20px 0;
    position: relative;
}

.left {
    width: 55px;
    height: 55px;
}

.left img {
    height: 100%;
    width: 100%;
    object-fit: contain;
}

.right {
    text-align: center;
}

.total-pnl {
    position: relative;
}

.total-balance .right span,
.roi .right span,
.total-pnl .right span {
    position: absolute;
    bottom: 5px;
    right: 50%;
    width: 100%;
    font-weight: 300;
    transform: translateX(50%);
}

.title {
    font-weight: 400;
    font-size: 16px;
    text-align: center;
    color: var(--white-to-black);
    margin-bottom: 10px;
    white-space: nowrap;
}

.value {
    font-weight: 500;
    font-size: 30px;
    color: var(--white-to-black);
    white-space: nowrap;
}


.total-balance {
    grid-column: 1/3;
    grid-row: 1/2;
}

.total-pnl {
    grid-column: 3/5;
    grid-row: 1/2;
}

.profitable-orders {
    grid-column: 1/3;
    grid-row: 2/3;
}

.roi {
    grid-column: 3/5;
    grid-row: 2/3;
}

.roi-value {
    background: linear-gradient(180deg, #E3FF72 0%, #3FBC2A 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    text-fill-color: transparent;
}


.chart-line {
    grid-column: 1/9;
    grid-row: 3/4;
}

.chart-line-head {
    display: grid;
    grid-template-columns: repeat(2, auto);
    place-items: center stretch;
    place-content: space-between;
}

.time {
    display: flex;
}

.time-item {
    margin-right: 8px;
    padding: 3px 10px;
    transition: all .3s ease-in-out;
    cursor: pointer;
    font-size: 14px;
    color: var(--white);
    font-weight: 500;
    border: .5px solid transparent;
}

.time-item.active {
    background-color: #2B834E;
    border: .1vw solid var(--fs-border);
}

.time-item.active h5 {
    font-weight: 400;
}

.time-item:last-child {
    margin-right: 0;
}


.chart-line thead {
    background-color: var(--background);
}

.chart-pie {
    grid-column: 5/9;
    grid-row: 1/3;
}

.chart-line h4,
.chart-pie h4 {
    font-weight: 400;
    font-size: 24px;
    color: var(--white-to-black);
}

.account {
    grid-column: span 2;
}

.account.active {
    -webkit-box-shadow: 4px 4px 19px 11px rgba(43, 131, 78, 0.35);
    -moz-box-shadow: 4px 4px 19px 11px rgba(43, 131, 78, 0.35);
    box-shadow: 4px 4px 19px 11px rgba(43, 131, 78, 0.35);
}

.sign {
    width: 25px;
    height: 25px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #0F111D;
    font-weight: bold;
    font-size: 1.4em;
    margin-right: 15px;
}

.real .balance {
    background-color: #C4C4C4;
}

.balances {
    display: grid;
    gap: 7px;
    grid-template-columns: repeat(2, 25px);
}

.balances img {
    width: 24px;
    height: 24px;
}

.head {
    display: flex;
    justify-content: space-between;
    margin-bottom: 8px;
}

.head h3 {
    font-weight: 600;
    font-size: 20px;
    color: var(--white-to-black);
}

.body .info {
    display: grid;
    grid-template-columns: repeat(2, auto);
    gap: 15px;
    margin-bottom: 14px;
}

.info h4 {
    font-weight: 300;
    font-size: 18px;
    color: var(--white-to-black);
}

.info .value {
    font-weight: 400;
    font-size: 18px;
    text-align: right;
}

.green-gradient {
    background: linear-gradient(180deg, #E3FF72 0%, #3FBC2A 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    text-fill-color: transparent;
}

.red-gradient {
    background: linear-gradient(180deg, #ff7282 0%, #bc2a36 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    text-fill-color: transparent;
}

.footer button {
    display: block;
    padding: 10px 0;
    width: 100%;
    font-weight: 400;
    font-size: 18px;
    color: var(--white);
    text-align: center;
    text-decoration: none;
}

.footer span.active {
    font-size: 16px;
    display: block;
    text-align: center;
    color: rgb(43, 131, 78);
}

@media (min-width: 1000px) {
    .dashboard {
        grid-template-rows: auto auto 15vw auto;
        gap: 1.5vw;
    }

    .dashboard > * {
        border-radius: .5vw;
        padding: 1vh;
    }

    .total-balance,
    .total-pnl,
    .profitable-orders,
    .roi {
        padding: 1vw 0;
    }

    .left {
        width: 2.8vw;
        height: 2.8vw;
    }

    .total-balance .right span,
    .roi .right span,
    .total-pnl .right span {
        bottom: .3vw;
        font-size: .7vw;
    }

    .title {
        font-size: .8vw;
        margin-bottom: .5vw;
    }

    .value {
        font-size: 1.5vw;
    }


    .time-item {
        margin-right: .4vw;
        padding: .15vw .5vw;
        font-size: .7vw;
        border: .1vw solid transparent;
    }

    .time-item.active {
        border: .1vw solid var(--fs-border);
    }

    .account.active {
        -webkit-box-shadow: .2vw .2vw .9vw .5vw rgba(43, 131, 78, 0.35);
        -moz-box-shadow: .2vw .2vw .9vw .5vw rgba(43, 131, 78, 0.35);
        box-shadow: .2vw .2vw .9vw .5vw rgba(43, 131, 78, 0.35);
    }

    .chart-line h4,
    .chart-pie h4 {
        font-size: 1vw;
    }

    .balances img {
        width: 1.25vw;
        height: 1.25vw;
    }

    .sign {
        width: 1.3vw;
        height: 1.3vw;
        font-size: 1.3vw;
        margin-right: .7vw;
    }

    .balances {
        gap: .3vw;
        grid-template-columns: repeat(2, 1.3vw);
    }

    .balances img {
        width: 1.25vw;
        height: 1.25vw;
    }

    .head {
        margin-bottom: .4vw;
    }

    .head h3 {
        font-size: 1vw;
    }

    .body .info {
        gap: .7vw;
        margin-bottom: .7vw;
    }

    .info h4 {
        font-size: .9vw;
    }

    .info .value {
        font-size: .9vw;
    }


    .footer button {
        padding: .5vw 0;
        font-size: .9vw;
    }

    .footer span.active {
        font-size: .8vw;
    }
}

@media (max-width: 1400px) {
    .dashboard {
        grid-template-rows: auto auto 250px auto;
    }

    .total-balance,
    .total-pnl,
    .profitable-orders,
    .roi {
        grid-template-columns: repeat(2, auto);
    }

    .value {
        font-size: 22px;
    }

    .left {
        width: 40px;
        height: 40px;
    }
}

/* ================================ mobile */

.dashboard.mobile {
    grid-template-columns: 1fr;
    grid-template-rows: auto;
    grid-auto-rows: auto;
    gap: 20px;
}

.dashboard.mobile > * {
    grid-column: 1/2;
    grid-row: span 1;
}

.dashboard.mobile h4 {
    font-size: 18px;
}

.dashboard.mobile .chart-line,
.dashboard.mobile .chart-pie {
    height: 250px;
}

</style>
